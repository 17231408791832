:root {
  --angle: 45deg;
  --opacity: 0.5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.rainbow {
  --border-size: 0.15rem;
  border: var(--border-size) solid transparent;

  /* Paint an image in the border */
  border-image: conic-gradient(
      from var(--angle),
      #48c269 0deg 90deg,
      #55c172 90deg 180deg,
      #6ad988 180deg 270deg,
      #8ddca1  270deg 360deg
    )
    1 stretch;
  background: rgb(255 255 255 / var(--opacity));
}

/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: '<number>';
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .rainbow {
    animation: rotate 10s linear infinite, opacityChange 3s infinite alternate;
  }

  /* Hide the warning */
  .warning {
    display: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

.activated {
  font-weight: bold;
}

.loader {
  width: 128px;
  height: 128px;
  border: 10px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.75s ease-in-out infinite;
  opacity: 1 !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
a:hover {
  color: white;
}
a:focus {
  color: white;
  border-bottom: 1px solid white;
}

@media (max-width: 500px) {
  .matchup-detail {
    font-size: 0.8em;
  }

  .matchup-detail h4 {
    font-size: 1rem;
  }

  .matchup-detail img {
    transform: scale(0.8);
  }
}
